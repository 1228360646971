import React from 'react';
import Helmet from 'react-helmet';
import cssVars from 'css-vars-ponyfill';
import image from '@images/seo.png';
import { GlobalStyles } from '@styles/global';

cssVars({});

export default (props) => {
  const { children } = props;
  return (
    <div>
      <Helmet
        title="Atelier Tudor Pavelescu"
        meta={[
          { name: 'title', content: 'Atelier Tudor Pavelescu' },
          { name: 'description', content: 'Atelier arhitectura Tudor Pavelescu' },
          { name: 'og:description', content: 'Atelier arhitectura Tudor Pavelescu' },
          { name: 'keywords', content: 'tudor pavelescu arhitectura atelier restaurari' },
          { name: 'title', content: 'Atelier Tudor Pavelescu', property: 'og:title' },
          { name: 'og:image', content: image },
          { name: 'image', property: 'og:image', content: image }
        ]}
      >
        <html lang="en" />
      </Helmet>
      <GlobalStyles />
      {children}
    </div>
  );
};
