import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    * {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

html,
body {
    margin: 0px;
    padding: 0px;
    background: white;
    color: #343434;
    overflow: auto;
    height: 100%;
}

.home{
    position: relative;
    top: -5%;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home h1{
    font-size: 36px;
    letter-spacing: 7px;
    font-weight: 100;
}

.contact-container{
    font-family: 'Raleway', sans-serif;
    color: #343434;
    font-weight: 300;
    font-size: 14px;
    position: fixed;
    bottom: 50px;
    right: 0px;
    left: 0px;

    .tel{
        text-decoration: none;
        padding-left: 10px;
        color: #343434;
    }

    .copyright{
        margin-top: 20px;
    }
}

.spinner-container{
    margin-top: 50px;
    
    h2{
        font-size: 16px;
        margin-top: 20px;
    }
}

.social-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

    .provider{
        padding: 20px;
        border-radius: 50%;
        display: flex;
        width: 100px;
        font-size: 44px;
        height: 100px;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
        background: #343434;
        transition: background .2s ease-out;
        margin: 20px;

        &:hover{
            background: black;
        }
    }


    @media(max-width: 700px){
        .provider{
            width: 50px;
            height: 50px;
            font-size: 20px;
        }
    }
}


.sk-folding-cube {
    margin: 20px auto;
    width: 20px;
    height: 20px;
    position: relative;
    -webkit-transform: rotateZ(45deg);
            transform: rotateZ(45deg);
  }
  
  .sk-folding-cube .sk-cube {
    float: left;
    width: 50%;
    height: 50%;
    position: relative;
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1); 
  }
  .sk-folding-cube .sk-cube:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #333;
    -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
            animation: sk-foldCubeAngle 2.4s infinite linear both;
    -webkit-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
            transform-origin: 100% 100%;
  }
  .sk-folding-cube .sk-cube2 {
    -webkit-transform: scale(1.1) rotateZ(90deg);
            transform: scale(1.1) rotateZ(90deg);
  }
  .sk-folding-cube .sk-cube3 {
    -webkit-transform: scale(1.1) rotateZ(180deg);
            transform: scale(1.1) rotateZ(180deg);
  }
  .sk-folding-cube .sk-cube4 {
    -webkit-transform: scale(1.1) rotateZ(270deg);
            transform: scale(1.1) rotateZ(270deg);
  }
  .sk-folding-cube .sk-cube2:before {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  .sk-folding-cube .sk-cube3:before {
    -webkit-animation-delay: 0.6s;
            animation-delay: 0.6s; 
  }
  .sk-folding-cube .sk-cube4:before {
    -webkit-animation-delay: 0.9s;
            animation-delay: 0.9s;
  }

  .home-container{
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  @keyframes sk-foldCubeAngle {
    0%, 10% {
      -webkit-transform: perspective(140px) rotateX(-180deg);
              transform: perspective(140px) rotateX(-180deg);
      opacity: 0; 
    } 25%, 75% {
      -webkit-transform: perspective(140px) rotateX(0deg);
              transform: perspective(140px) rotateX(0deg);
      opacity: 1; 
    } 90%, 100% {
      -webkit-transform: perspective(140px) rotateY(180deg);
              transform: perspective(140px) rotateY(180deg);
      opacity: 0; 
    }
  }
`;
