import React from 'react';
import { BaseLayout } from '@layouts';
import Fade from 'react-reveal/Fade';
import Flip from 'react-reveal/Flip';
import { FaFacebookF, FaLinkedin, FaInstagram } from 'react-icons/fa';

export default () => {
  return (
    <div id="theme">
      <BaseLayout>
        <InProgress />
      </BaseLayout>
    </div>
  );
};

const InProgress = (props) => {
  return (
    <div className="home-container">
      <Fade>
        <div className="home">
          <h1>Atelier Tudor Pavelescu </h1>
          <div className="social-container">
            <Flip top>
              <div className="provider">
                <FaFacebookF />
              </div>
            </Flip>
            <Flip top delay="300">
              <div className="provider">
                <FaInstagram />
              </div>
            </Flip>
            <Flip top delay="600">
              <div className="provider">
                <FaLinkedin />
              </div>
            </Flip>
          </div>

          <Fade delay="1500">
            <div className="spinner-container">
              <div className="sk-folding-cube">
                <div className="sk-cube1 sk-cube" />
                <div className="sk-cube2 sk-cube" />
                <div className="sk-cube4 sk-cube" />
                <div className="sk-cube3 sk-cube" />
              </div>
              <h2>UNDER CONSTRUCTION</h2>
            </div>
          </Fade>
          <Fade bottom delay="1500">
            <div className="contact-container">
              Contact
              {' '}
              <a className="tel" href="tel:+40741197535">+4 0741 197 535</a>
              <div className="copyright">
                <span>@</span>
                {' '}
                2020 Atelier Tudor Pavelescu - All rights reserved
              </div>
            </div>
          </Fade>
        </div>
      </Fade>
    </div>
  );
};
